import React from "react";
import icon from "images/png/mike.png";
import { Card } from "../card/Card.component";
import { Quotes } from "components/svg-wrapper/components/shapes/Quotes.svg";
import "./PacBayQuotes.styles.scss";
import { graphql, useStaticQuery } from "gatsby";

const cardColors = ["var(--blue)", "var(--secondary)", "var(--tertiary)"];
const cardRotations = ["-1", "2", "-2", "1"];

export const PacBayQuotes = () => {
  const {
    allContentfulQuoteCard: { edges: cards },
  } = useStaticQuery(graphql`
    query PacBayQuoteCardsQuery {
      allContentfulQuoteCard(sort: { fields: order, order: ASC }) {
        edges {
          node {
            id
            quote
            quotee
          }
        }
      }
    }
  `);

  return (
    <div className="layout pac-bay-quotes">
      <div className="top-row">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img className="mike-image-responsive" draggable={false} src={icon} />
        </div>
        <div className="main-quote-wrapper">
          <Quotes color="var(--black)" width="2.33rem" />
          <p className="main-quote-text">
            PacBay offers a learning environment where students can slow down,
            go deeper, and feel supported.
          </p>
          <p className="quotee-text">-Dr. Michael Chen, Head of School</p>
        </div>
      </div>
      <div className="bottom-row">
        <h3 className="no-margin">What people are saying about PacBay</h3>
        <div className="container">
          <div className="card-wrapper">
            {cards.map(({ node: card }, cardIndex) => {
              const chosenColorVariantIndex = cardIndex % cardColors.length;
              const chosenRotationVariantIndex =
                cardIndex % cardRotations.length;
              return (
                <Card
                  key={card.id}
                  quote={card.quote}
                  quotee={card.quotee}
                  color={cardColors[chosenColorVariantIndex]}
                  rotationDegree={cardRotations[chosenRotationVariantIndex]}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
