import React from "react";

export const Quotes = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      viewBox="0 0 33 27"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.797620501"
      >
        <g
          id="Icons/Quote/Black"
          fill={color}
          fillOpacity={opacity}
          fillRule="nonzero"
        >
          <path
            d="M9.47524752,0 C3.92003575,3.98360656 0,10.5122951 0,17.704918 C0,23.5696721 3.48514851,27 7.51485149,27 C11.3267327,27 14.1584158,23.9016393 14.1584158,20.25 C14.1584158,16.5983607 11.6534653,13.942623 8.38613861,13.942623 C7.73267327,13.942623 6.86138614,14.0532787 6.64356436,14.1639344 C7.18811881,10.4016393 10.6732673,5.97540984 14.1584158,3.76229508 L9.47524752,0 Z M28.2079208,0 C22.7623762,3.98360656 18.8415842,10.5122951 18.8415842,17.704918 C18.8415842,23.5696721 22.3267327,27 26.3564356,27 C30.0594059,27 33,23.9016393 33,20.25 C33,16.5983607 30.3861386,13.942623 27.1188119,13.942623 C26.4653465,13.942623 25.7029703,14.0532787 25.4851485,14.1639344 C26.029703,10.4016393 29.4059406,5.97540984 32.8910891,3.76229508 L28.2079208,0 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};
