import { SchoolCard } from "components/school-card/SchoolCard.component";
import { StackedCallToAction } from "components/stacked-call-to-action/StackedCallToAction.component";
import { SVGIcons } from "components/svg-wrapper/SvgWrapper.enum";
import { motion, useAnimation } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useWindowWidth } from "hooks/windowWidth.hook";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import linksService from "services/links.service";
import {
  GET_MORE_INFO,
  QUESTION_MINIMIZE_BREAKPOINT,
} from "../../../constants";
import "./KeyEthos.styles.scss";
import { h3Variants, liVariants, ulVariants } from "./KeyEthos.variants";

export const KeyEthos = () => {
  const windowWidth = useWindowWidth();
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.25,
  });

  const {
    allContentfulKeyEthos: { edges: statements },
  } = useStaticQuery(graphql`
    query KeyEthosQuery {
      allContentfulKeyEthos(sort: { fields: order, order: ASC }) {
        edges {
          node {
            id
            keyEthos {
              keyEthos
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const isMobile = windowWidth <= QUESTION_MINIMIZE_BREAKPOINT;

  return (
    <>
      <div className="layout key-ethos">
        <div ref={ref}>
          <motion.h3 variants={h3Variants} initial="hidden" animate={controls}>
            Key ethos
          </motion.h3>
          <motion.ul
            className="list-wrapper enlarged-list-size"
            variants={ulVariants}
            initial="hidden"
            animate={controls}
          >
            {statements.map(({ node: statement }) => (
              <motion.li key={statement.id} variants={liVariants}>
                {statement.keyEthos.keyEthos}
              </motion.li>
            ))}
          </motion.ul>
        </div>
        <div>
          <h3>Our Schools</h3>
          <section className="school-cards-wrapper">
            <SchoolCard
              link={linksService.highSchool()}
              label="High School: &nbsp;&nbsp;9th - 12th"
              hoverText="26 APs, 90 Electives, 6 World Languages"
            >
              <StaticImage
                src="../../../../images/jpg/engineering_workshop.jpg"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
            </SchoolCard>
            <SchoolCard
              link={linksService.middleSchool()}
              label="Middle School: &nbsp;&nbsp;6th - 8th"
              hoverText="We focus on spiritual formation and character development"
            >
              <StaticImage
                src="../../../../images/jpg/middle_school_card.jpg"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
            </SchoolCard>
          </section>
        </div>
      </div>
      <StackedCallToAction
        headerText={
          isMobile ? GET_MORE_INFO.HEADER.MOBILE : GET_MORE_INFO.HEADER.DESKTOP
        }
        paragraphText={
          isMobile
            ? GET_MORE_INFO.PARAGRAPH.MOBILE
            : GET_MORE_INFO.PARAGRAPH.DESKTOP
        }
        buttonObj={{
          label: GET_MORE_INFO.BUTTON_LABEL.DESKTOP,
          icon: SVGIcons.Document,
          link: linksService.inquiryForm(),
          newTab: true,
        }}
      />
    </>
  );
};
