import React from "react";

export const Star = ({
  width,
  height,
  color,
  outline,
  opacity = "1",
  minWidth,
  minHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ minWidth, minHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      viewBox="0 0 58 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Homepage"
          transform="translate(-254.000000, -2035.000000)"
          stroke={outline}
          fill={color}
          fillOpacity={opacity}
        >
          <g
            id="Parent-Satisfaction-Stat"
            transform="translate(204.000000, 1978.000000)"
          >
            <g id="Content" transform="translate(49.000000, 25.000000)">
              <path
                d="M30.1372549,33.0215643 L38.6814766,48.2446106 L57.6190528,50.6642888 L43.9218663,62.4043631 L47.1734867,79.0746819 L30.1372549,71.1991589 L13.1010231,79.0746819 L16.3526436,62.4043631 L2.65545705,50.6642888 L21.5930332,48.2446106 L30.1372549,33.0215643 Z"
                id="Star"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
