import { Button } from "components/button/Button.component";
import { Link } from "components/link/Link.component";
import { Star } from "components/svg-wrapper/components/shapes/Star.svg";
import { SVGIcons } from "components/svg-wrapper/SvgWrapper.enum";
import { Context } from "contexts/Context.store";
import { motion, useAnimation } from "framer-motion";
import { useWindowWidth } from "hooks/windowWidth.hook";
import React, { useContext, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import linksService from "services/links.service";
import {
  PANDEMIC_SATISFACTION,
  SCHEDULE_A_VISIT,
} from "./PostSplash.constants";
import "./PostSplash.styles.scss";

const actionVariants = {
  hidden: { opacity: 0, transform: "translateX(-20rem)" },
  visible: {
    opacity: 1,
    transform: "translateX(0px)",
    transition: {
      duration: 1,
    },
  },
};

export const PostSplash = () => {
  const windowWidth = useWindowWidth();
  const { isScrollYPassedTriggerPoint } = useContext(Context);
  const actionControls = useAnimation();
  const [actionRef, actionIsInView] = useInView({
    threshold: 0.05,
  });

  useEffect(() => {
    if (windowWidth) {
      // for animating when animation not working properly
      const isAutoAnimateWidth = windowWidth <= 360;
      if (actionIsInView || isAutoAnimateWidth) {
        actionControls.start("visible");
      }
    }
  }, [actionControls, actionIsInView, windowWidth]);

  return (
    <motion.section
      className="post-splash"
      initial={{ backgroundColor: "var(--lavenderRGBA)" }}
      animate={{
        backgroundColor: isScrollYPassedTriggerPoint(1200)
          ? "var(--lavender)"
          : "var(--lavenderRGBA)",
      }}
    >
      <motion.div
        ref={actionRef}
        variants={actionVariants}
        initial="hidden"
        animate={actionControls}
        style={{ paddingBottom: "7.5rem" }}
      >
        <Link to={linksService.scheduleVisit()} newTab className="no-underline">
          <Button
            className="xl"
            tabIndex={-1}
            icon={SVGIcons.Calendar}
            fullWidth
            hoverAnimation
          >
            {SCHEDULE_A_VISIT}
          </Button>
        </Link>
      </motion.div>
      <div className="star-fact-grouping layer-1">
        <Star
          color="var(--secondary)"
          outline="var(--black)"
          width="2.5rem"
          minWidth="2.5rem"
        />
        <p>{PANDEMIC_SATISFACTION}</p>
      </div>
    </motion.section>
  );
};
