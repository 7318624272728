export const h3Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

export const ulVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      type: "tween",
    },
  },
};

export const liVariants = {
  hidden: {
    opacity: 0,
    transform: "translateX(-5rem)",
  },
  visible: {
    opacity: 1,
    transform: "translateX(0rem)",
  },
};
