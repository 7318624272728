import React from "react";
import { Loading } from "images/svg/cursive/bad-script/Loading.svg";
import "./Loading.styles.scss";

export const LoadingComponent = () => {
  return (
    <main className="relative-page-container loading-background layer-2">
      <div className="loading-shadow-overlay">
        <Loading className="centered" width="20rem" color="var(--white)" />
      </div>
    </main>
  );
};
