import React from "react";
import { Quotes } from "components/svg-wrapper/components/shapes/Quotes.svg";
import "./Card.styles.scss";

export const Card = ({ quote, quotee, color, rotationDegree }) => {
  return (
    <article
      className="card"
      style={{ transform: `rotate(${rotationDegree ?? 0}deg)` }}
    >
      <div
        style={{
          padding: "1.2rem 1.2rem 0rem 1.2rem",
          display: "flex",
          flexDirection: "column",
          alignContent: "space-between",
        }}
      >
        <Quotes color={color} width="1.5rem" />

        <div
          style={{
            height: "4.35rem",
            display: "inherit",
          }}
        >
          <p style={{ height: "100%" }}>{quote}</p>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: "2.72rem",
          display: "flex",
          alignItems: "center",
          padding: "1.2rem",
          backgroundColor: color,
        }}
      >
        <p className="quotee">-{quotee}</p>
      </div>
    </article>
  );
};
