import { BlueGrouping } from "components/color-groupings/blue-grouping/BlueGrouping.component";
import { WhiteGrouping } from "components/color-groupings/white-grouping/WhiteGrouping.component";
import { KeyEthos } from "components/page-specific-components/home/key-ethos/KeyEthos.component";
import { LoadingComponent } from "components/page-specific-components/home/loading/Loading.component";
import { PacBayQuotes } from "components/page-specific-components/home/pac-bay-quotes/PacBayQuotes.component";
import { PostSplash } from "components/page-specific-components/home/splash-page/post-splash/PostSplash.component";
import { Splash } from "components/page-specific-components/home/splash-page/splash/Splash.component";
import { SEO } from "components/seo/SEO.component";
import { Context } from "contexts/Context.store";
import { AnimatePresence } from "framer-motion";
import { useRelatedPages } from "hooks/relatedPages.hook";
import { useWindowWidth } from "hooks/windowWidth.hook";
import React, { useContext, useEffect, useState } from "react";
import linksService from "services/links.service";
import { RELATED_PAGES_LABELS } from "components/constants";

export default () => {
  const [splashPageBackgroundImage, setSplashPageBackgroundImage] =
    useState(null);
  const { isSplashPageLoading, toggleIsSplashPageLoading } =
    useContext(Context);
  const windowWidth = useWindowWidth();

  // fail safe to force loading to false if loading image fails
  const terminateLoadingFailSafe = () => {
    setTimeout(() => {
      toggleIsSplashPageLoading(false);
    }, 1250);
  };

  useEffect(() => {
    //* scroll to top for loading logic
    window.scrollTo(0, 0);

    setSplashPageBackgroundImage(
      windowWidth > 600
        ? new Image()
        : new Image("src/images/jpg/splash_page_mobile.jpg")
    );
    toggleIsSplashPageLoading(true);
  }, []);

  useEffect(() => {
    if (splashPageBackgroundImage) {
      const isBackgroundImageLoaded =
        splashPageBackgroundImage.complete &&
        splashPageBackgroundImage.naturalHeight !== 0;
      if (isBackgroundImageLoaded) {
        toggleIsSplashPageLoading(false);
      } else {
        splashPageBackgroundImage.onload = () => {
          toggleIsSplashPageLoading(false);
        };
        terminateLoadingFailSafe();
      }
    } else {
      terminateLoadingFailSafe();
    }
  }, [
    splashPageBackgroundImage,
    splashPageBackgroundImage?.complete,
    splashPageBackgroundImage?.naturalHeight,
  ]);

  useRelatedPages([
    { label: RELATED_PAGES_LABELS.OUR_STORY, link: linksService.ourStory() },
    { label: RELATED_PAGES_LABELS.ADMISSIONS, link: linksService.admissions() },
    {
      label: RELATED_PAGES_LABELS.HOW_TO_APPLY,
      link: linksService.howToApply(),
    },
  ]);

  return (
    <>
      <SEO title="Home" description="this is the home page" />
      <main>
        <AnimatePresence>
          {isSplashPageLoading ? (
            <LoadingComponent />
          ) : (
            <>
              <Splash />
              <PostSplash />
              <WhiteGrouping>
                <KeyEthos />
              </WhiteGrouping>
              <BlueGrouping>
                <PacBayQuotes />
              </BlueGrouping>
            </>
          )}
        </AnimatePresence>
      </main>
    </>
  );
};
