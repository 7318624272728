import { Context } from "contexts/Context.store";
import { AnimatePresence, motion } from "framer-motion";
import { useInterval } from "hooks/interval.hook";
import React, { useContext, useState } from "react";
import "./Splash.styles.scss";

export const Splash = () => {
  const { isScrollYPassedTriggerPoint } = useContext(Context);
  const [index, setIndex] = useState(0);
  const delayStaggerChildren = 0.4;

  const parentVariants = {
    unmounted: {
      opacity: 0,
      color: "var(--white)",
    },
    mounted: {
      opacity: 1,
      color: isScrollYPassedTriggerPoint(1) ? "var(--primary)" : "var(--white)",
    },
  };

  const childVariants = {
    unmounted: {
      opacity: 0,
    },
    mounted: {
      opacity: 1,
      transition: {
        duration: 0.6,
      },
    },
  };

  const firstVerb = index === 0 || index === 1;
  const secondVerb = index === 2;
  const thirdVerb = index === 3;

  const firstAdjective = index === 0;
  const secondAdjective = index === 1;
  const thirdAdjective = index === 2;
  const fourthAdjective = index === 3;

  useInterval(() => {
    if (index < 3) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
  }, 3000);

  return (
    <>
      {/* fixed background color */}
      <section className="fixed-splash-container homepage-background" />
      {/* fixed background image */}
      <motion.section
        className="fixed-splash-container homepage"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1,
          delay:
            delayStaggerChildren + delayStaggerChildren + delayStaggerChildren,
        }}
      />
      {/* relative content */}
      <motion.section
        className="relative-page-container"
        initial={{ backgroundColor: "var(--shadowOverlay)" }}
        animate={{
          backgroundColor: isScrollYPassedTriggerPoint(1)
            ? "var(--lavenderRGBA)"
            : "var(--shadowOverlay)",
        }}
      >
        <motion.h1
          className="bottom-left-rule-of-thirds text-shadow splash-text-placement splash-text"
          variants={parentVariants}
          initial="unmounted"
          animate="mounted"
          transition={{
            delayChildren: delayStaggerChildren,
            staggerChildren: delayStaggerChildren,
            color: {
              duration: 0.5,
            },
            default: {
              duration: 1,
            },
          }}
        >
          {/* consistent pronoun */}
          <motion.span variants={childVariants}>We</motion.span>
          &nbsp;
          {/* variant verbs */}
          <span style={{ position: "relative" }}>
            <AnimatePresence>
              {firstVerb && (
                <motion.span
                  style={{ position: "absolute" }}
                  variants={childVariants}
                >
                  Are
                </motion.span>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {secondVerb && (
                <motion.span
                  style={{ position: "absolute" }}
                  variants={childVariants}
                >
                  Love
                </motion.span>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {thirdVerb && (
                <motion.span
                  style={{ position: "absolute" }}
                  variants={childVariants}
                >
                  Learn
                </motion.span>
              )}
            </AnimatePresence>
            &nbsp;
          </span>
          {/* variant adjectives */}
          <div style={{ position: "relative" }}>
            <AnimatePresence>
              {firstAdjective && (
                <motion.div
                  style={{ position: "absolute" }}
                  variants={childVariants}
                >
                  Ambitious
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {secondAdjective && (
                <motion.div
                  style={{ position: "absolute" }}
                  variants={childVariants}
                >
                  Joyful
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {thirdAdjective && (
                <motion.div
                  style={{ position: "absolute" }}
                  variants={childVariants}
                >
                  To Learn
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {fourthAdjective && (
                <motion.div
                  style={{ position: "absolute" }}
                  variants={childVariants}
                >
                  To Love
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </motion.h1>
      </motion.section>
    </>
  );
};
